import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import axios from "axios";
import Footer from "../../components/Footer.jsx";
import BackButton from "../../components/BackButton.jsx";
import AnimationLine from "../../components/AnimationLine.jsx";
import AnimationText from "../../components/AnimationText.jsx";
import Menu from "../../components/Menu.jsx";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button.jsx";
import { useLocation } from "react-router-dom";
import { useDetail } from "../../store";

function Item({ jump, t }) {
  const [URL, setURL] = useState("");
  const location = useLocation();
  const { i18n } = useTranslation();
  const detailList = useDetail((state) => state.detail.en);
  // 获取查询参数
  const queryParams = new URLSearchParams(location.search);
  let itemId = Number(queryParams.get("id"));
  useEffect(() => {
    if (i18n.language === "zh") {
      setURL(`${window.iframeOrigin}/itemzh${itemId}/`);
    } else {
      setURL(`${window.iframeOrigin}/item${itemId}/`);
    }
  }, [i18n.language, itemId]);

  return (
    <div className="h-full relative">
      <iframe
        title="cardItem"
        className="w-full h100 px-4"
        allow="accelerometer"
        src={URL}
      ></iframe>
      <div className="fixed bottom-6 left-6 z-50 lg:p-6 bg-transparent">
        <Menu
          onClickOne={() => {
            const index = detailList.findIndex(
              (item, index) => item.id === itemId
            );
            if (index === 0) {
              jump("/detail", `id=${detailList[detailList.length - 1].id}`);
            } else {
              jump("/detail", `id=${detailList[index - 1].id}`);
            }
          }}
          onClickTwo={() => {
            jump(`/item`);
          }}
          onClickThree={() => {
            const index = detailList.findIndex(
              (item, index) => item.id === itemId
            );
            if (index === detailList.length - 1) {
              jump("/detail", `id=${detailList[0].id}`);
            } else {
              jump("/detail", `id=${detailList[index + 1].id}`);
            }
          }}
          t={t}
        />
      </div>
      <Footer jump={jump} t={t} />
    </div>
  );
}

export default Item;
