// App.js
import React, { useState, useEffect, useRef } from "react";
import { HashRouter as Router, Route, Routes, Link } from "react-router-dom";
import Loading from "./components/Loading.jsx";
import { useDetail, useLoading, useCardList } from "./store.js";
import axios from "axios";
import Reveal from "./components/Reveal.jsx";
import Header from "./components/Header.jsx";
import Home from "./pages/Home";
import Scene from "./pages/HomeScene";
import About from "./pages/About";
import Item from "./pages/Item";
import Partner from "./pages/Partner";
import Cards from "./pages/Cards";
import Awards from "./pages/Awards";
import { useTranslation } from "react-i18next";

const cardPath = "/card";
export const App = () => {
  const setLoading = useLoading((state) => state.setLoading);
  const loading = useLoading((state) => state.loading);
  const setDetail = useDetail((state) => state.setDetail);
  const setCardList = useCardList((state) => state.setCardList);
  const revealRef = useRef();
  const { t } = useTranslation();
  // const { nodes, materials } = useGLTF(window.filePath + "card.glb");
  // const { scene } = useGLTF(window.filePath + "cardBox.glb");
  // const [metalnessMap, roughnessMap] = useTexture([
  //   window.filePath + "metalness.png", // 金属贴图
  //   window.filePath + "roughness.png", // 粗糙度贴图
  // ]);
  const jump = (path, ags = "") => {
    revealRef.current.reveal(path, ags);
  };
  const load = async () => {
    setLoading(true);
    setTimeout(() => {
      // 15秒后强制加载完成
      setLoading(false);
    }, [15000]);
    const loadGLBFile = async (url) => {
      // Fetch the GLB file
      const response = await fetch(url, {
        cache: "force-cache", // Use the cached version if available
      });

      if (!response.ok) {
        console.error("Failed to load GLB file");
        return;
      }

      const blob = await response.blob();

      // Create a URL for the blob and store it for later use
      const blobUrl = URL.createObjectURL(blob);
      localStorage.setItem("cardGLB", blobUrl);
    };
    const loadImage = (url, index, total) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        if (index === total) {
          setTimeout(() => {
            setLoading(false);
          }, [100]);
        }
      };
    };
    const fetchData = async () => {
      try {
        let arr = [];
        const response = await axios.get("/card/data/cards.json", {
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        });
        const detailZH = await axios.get("/card/data/detail_zh.json", {
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        });
        const detailEN = await axios.get("/card/data/detail_en.json", {
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        });
        arr = response.data;
        setDetail({
          zh: detailZH.data,
          en: detailEN.data,
        });
        let images = arr
          .map((item) => cardPath + item.images.map)
          .filter((item) => item !== "/cardundefined");
        for (let i = 0; i < images.length; i++) {
          loadImage(images[i], i, images.length - 1);
        }
        let originalList = [
          {
            category: "金色",
            from: 0,
            len: (2 * Math.PI) / 3,
            visible: true,
            list: arr.filter((item) => item.rarity === ""),
          },
          {
            category: "蓝色",
            from: (2 * Math.PI) / 3,
            len: (2 * Math.PI) / 3,
            visible: true,
            list: arr.filter((item) => item.rarity === "Rare Ultra"),
          },
          {
            category: "绿色",
            from: (4 * Math.PI) / 3,
            len: (2 * Math.PI) / 3,
            visible: true,
            list: arr.filter((item) => item.rarity === "Rare Rainbow"),
          },
        ];
        // 计算每个卡片的角度
        originalList.forEach((listItem, listIndex) => {
          listItem.list = listItem.list.map((item, index) => {
            const fondLabelEN = detailEN.data.find(
              (detailItem) => detailItem.id === item.id
            );
            const fondLabelZH = detailZH.data.find(
              (detailItem) => detailItem.id === item.id
            );
            return {
              ...item,
              angle:
                listItem.from + (index / listItem.list.length) * listItem.len,
              label: [...fondLabelEN.label, ...fondLabelZH.label],
              tag: [...fondLabelEN.tag, ...fondLabelZH.tag],
            };
          });
        });
        setCardList(originalList);
      } catch (error) {
        console.error("Error fetching the data", error);
      }
    };
    console.log(1);
    await loadGLBFile(window.filePath + "cardBox.glb");
    console.log(2);
    await loadGLBFile(window.filePath + "card.glb");
    console.log(3);
    await fetchData();
    console.log(4);
  };
  const IframeLoad = () => {
    console.log("IframeLoad");
    load();
  };

  return (
    <>
      {loading && (
        <div>
          <span className="fontThin">oxyz3</span>
          <span className="fontNormal">oxyz3</span>
          <span className="fontBold">oxyz3</span>
        </div>
      )}
      <Router>
        <Header t={t} jump={jump} />
        <Reveal ref={revealRef}></Reveal>
        <Cards></Cards>
        <Scene IframeLoad={IframeLoad}></Scene>
        <Loading />
        <Routes>
          <Route exact path="/" element={<Home t={t} jump={jump} />} />
          <Route path="/about" element={<About t={t} jump={jump} />} />
          <Route path="/item" element={<Home t={t} jump={jump} />} />
          <Route path="/detail" element={<Item t={t} jump={jump} />} />
          <Route path="/partner" element={<Partner t={t} jump={jump} />} />
          <Route path="/awards" element={<Awards t={t} jump={jump} />} />
        </Routes>
      </Router>
    </>
  );
};
