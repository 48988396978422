import React, { useLayoutEffect, useRef } from "react";
import BackButton from "../../components/BackButton.jsx";
import styles from "./index.module.scss";
const Awards = ({ t }) => {
  return (
    <div className="h100 w-full overflow-hidden lg:flex lg:flex-row ">
      <div className={styles.timelineBox}>
        <ul className={styles["timeline"]}>
          <li className={styles["timeline-event"]}>
            <label className={styles["timeline-event-icon"]}></label>
            <div className={styles["timeline-event-copy"]}>
              <p className={styles["timeline-event-thumbnail"]}>{t("IDFA")}</p>
              <h3>{t("树叶计划")}</h3>
              <h4>{t("官方甄选")}</h4>
              <div className="w-full flex items-center justify-center">
                <img src={window.filePath + "three.png"} alt="" />
              </div>
            </div>
          </li>
          <li className={styles["timeline-event"]}>
            <label className={styles["timeline-event-icon"]}></label>
            <div className={styles["timeline-event-copy"]}>
              <p className={styles["timeline-event-thumbnail"]}>
                {t("Hilibrand Awards 2022")}
              </p>
              <h3>{t("logo设计")}</h3>
              <h4>{t("优异奖")}</h4>
              <div className="w-full flex items-center justify-center">
                <img src={window.filePath + "LOGO design.png"} alt="" />
              </div>
            </div>
          </li>
          <li className={styles["timeline-event"]}>
            <label className={styles["timeline-event-icon"]}></label>
            <div className={styles["timeline-event-copy"]}>
              <p className={styles["timeline-event-thumbnail"]}>{t("SXSW")}</p>
              <h3>{t("众方纪元")}</h3>
              <h4>{t("观众")}</h4>
              <div className="w-full flex items-center justify-center">
                <img src={window.filePath + "sxsw.png"} alt="" />
              </div>
            </div>
          </li>
          <li className={styles["timeline-event"]}>
            <label className={styles["timeline-event-icon"]}></label>
            <div className={styles["timeline-event-copy"]}>
              <p className={styles["timeline-event-thumbnail"]}>{t("红点")}</p>
              <h3>{t("太空白蛇传")}</h3>
              <h4>{t("包装设计")}</h4>
              <div className="w-full flex items-center justify-center">
                <img src={window.filePath + "red.png"} alt="" />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <VideoBackground></VideoBackground>
      <div className={styles["filters-background"]}></div>
    </div>
  );
};
const VideoBackground = () => {
  const videoRef = useRef(null);
  useLayoutEffect(() => {
    function doPlay() {
      window.WeixinJSBridge.invoke("getNetworkType", {}, function (e) {
        videoRef.current.play();
      });
    }

    if (window.WeixinJSBridge) {
      doPlay();
    } else {
      document.addEventListener(
        "WeixinJSBridgeReady",
        function () {
          doPlay();
        },
        false
      );
    }
  }, []);

  return (
    <div className={styles["video-background"]}>
      <video
        ref={videoRef}
        x5-video-player-type="h5"
        preload="metadata"
        playsInLine="true"
        webkit-playsinline="true"
        x-webkit-airplay="true"
        x5-video-orientation="portraint"
        x5-video-player-fullscreen="true"
        autoPlay
        loop
        muted
        playsInline
        className={styles["video"]}
      >
        <source src={window.filePath + "bg-video.mp4"} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Awards;
