import imagesLoaded from "imagesloaded";

export const typewriter = (
  el,
  str,
  speed = 50,
  increment = 2,
  theLetters = "abcdefghijklmnopqrstuvwxyz"
) => {
  let clen = str.length;
  let si = 0;
  let stri = 0;
  let block = "";
  let fixed = "";
  (function rustle(i) {
    setTimeout(function () {
      if (--i) {
        rustle(i);
      }
      nextFrame(i);
      si = si + 1;
    }, speed);
  })(clen * increment + 1);

  function nextFrame(pos) {
    for (let i = 0; i < clen - stri; i++) {
      let num = Math.floor(theLetters.length * Math.random());
      let letter = theLetters.charAt(num);
      // block = block + letter;
      block = letter;
    }
    if (si === increment - 1) {
      stri++;
    }
    if (si === increment) {
      fixed = fixed + str.charAt(stri - 1);
      si = 0;
    }
    el.innerText = fixed + block;
    block = "";
  }
};

export const typewriter2 = (
  el,
  str,
  speed = 30,
  iteration = 0,
  letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
) => {
  let interval = null;

  clearInterval(interval);

  interval = setInterval(() => {
    el.innerText = el.innerText
      .split("")
      .map((letter, index) => {
        if (index < iteration) {
          return str[index];
        }
        return letters[Math.floor(Math.random() * 26)];
      })
      .join("");

    if (iteration >= str.length) {
      clearInterval(interval);
    }
    iteration += 1 / 3;
  }, speed);
};
/**
 * Preloads images specified by the CSS selector.
 * @function
 * @param {string} [selector='img'] - CSS selector for target images.
 * @returns {Promise} - Resolves when all specified images are loaded.
 */
// export const preloadImages = (selector = "img") => {
//   return new Promise((resolve) => {
//     // The imagesLoaded library is used to ensure all images (including backgrounds) are fully loaded.
//     imagesLoaded(
//       document.querySelectorAll(selector),
//       { background: true },
//       resolve
//     );
//   });
// };

// /**
//  * Throttles a function call, allowing it to be executed only once every specified time period.
//  * This is useful for controlling the rate at which a function is executed, preventing it from being called too frequently.
//  * @function
//  * @param {Function} func - The function to be throttled.
//  * @param {number} limit - The time, in milliseconds, to wait before allowing the function to be called again.
//  * @returns {Function} - A throttled version of the passed function, which when invoked, will only execute
//  * at most once in every `limit` milliseconds, and ignores subsequent calls within the same period.
//  */
// export const throttle = (func, limit) => {
//   let inThrottle;
//   return function () {
//     const args = arguments;
//     const context = this;
//     if (!inThrottle) {
//       func.apply(context, args);
//       inThrottle = true;
//       setTimeout(() => (inThrottle = false), limit);
//     }
//   };
// };
